input,
textarea {
    background-color: rgb(243 244 246);
    border: 1px solid #ccc;
    border-radius: 4px;
    border-color: rgb(185, 185, 185);
    padding: 3px;
}

.button {
    background-color: rgb(60, 120, 189);
    padding: 8px;
    border-radius: 7px;
    border: 0px solid #ccc !important;
    color: white;
    font-weight: bold;
}

.spinnerstyle {
    position: fixed;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
}